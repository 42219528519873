import * as React from "react";
import { About, Layout } from "../components";
import Seo from "../components/Seo";

const AboutPage = () => {
  return (
    <Layout>
      <Seo
        title="東京横丁について"
        description="東京横丁が掲げるビジョンやミッションステートメントをご紹介します。"
        keywords="スキマ時間をもっと楽しく,東京横丁,とうきょうよこちょう,TOKYO YOKOCHO,ビジョン,ミッション,カルチャー"
      />
      <About />
    </Layout>
  );
};

export default AboutPage;
